import React from 'react'
import './Allservices.css'

export default function Allservices() {
  return (
    <div className='Allservices'>
    <br></br>
      <p className='ALLservicesP'>
      <h3> Sähköauton latausratkaisut</h3>
      <br></br>
      Toteutamme sähköauton latausratkaisuja omakotitaloihin, taloyhtiöihin ja yrityksille.
      <br></br>
      Lisätietoa löydät osiosta <a href="/services"> Latausratkaisut</a>.
      <br></br><br></br>
      <h3> Valaistustyöt</h3>
      <br></br>
      Tarjoamme laadukkaasti ja ammattitaitoisesti toteutettuja valaistusratkaisuja niin sisä- kuin ulkotiloihin.
      Päivitämme esimerkiksi vanhat valaisimet uusiin energiatehokkaisiin LED-valaisimiin. Näin saat 
      valaistuksesi energiatehokkaaksi ja ympäristöystävälliseksi. Hinnoittelemme valaistustyöt tapauskohtaisesti,
        ota yhteyttä niin keskustellaan asiasta lisää!
        <br></br><br></br>
      <h3> Sähkösaneeraukset</h3>
      <br></br>
        Teemme sähkösaneerauksia niin vanhoihin kuin uudempiinkin kohteisiin. Sähkösaneerauksella tarkoitetaan sähköjärjestelmän
        uudistamista ja päivittämistä. Sähkösaneerauksella voidaan parantaa sähköturvallisuutta ja energiatehokkuutta.
        <br></br><br></br>
        <h3>Teollisuuden sähkötyöt</h3>
        <br></br>
        Teemme sähköasennuksia ja kunnossapitotöitä teollisuuskohteisiin.
        Meillä on pitkä kokemus vuosien varrelta teollisuuden sähkötöistä.
        Tuomme osaamisemme ja ammattitaitomme mielellään käyttöösi.
        <br></br><br></br>
        <h3>Kotitalousvähennys</h3>
        <br></br>
        Meiltä kotiin ostetuista sähköasennuksista on mahdollista saada kotitalousvähennystä asennustyön osasta 40%.
        Vähennyksen maksimi on 2 250 euroa ja omavastuu vuodessa 100 euroa.(Verohallinnon tiedot 2023)
        <br></br><br></br>
    </p>
       </div>
  )
}
