import React from 'react';
import './Contact.css';
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";

export default function Contact() {
  return (
    <div className='contactWrapper'>
      <div className='contactContainer'>
        <h2>Työtilaukset & asennukset</h2>
        <p>___________________________</p>
        <p><MdAccountCircle /> Konsta Juola</p>
        <p><FaPhoneAlt /> +358 50 412 4351</p>
        <p><MdOutlineEmail/> info@jekotek.fi</p>
        <a href="mailto:info@jekotek.fi">Ota yhteyttä sähköpostilla</a>
      </div>

      <div className='contactContainer' style={{ marginBottom: '140px' }}>
        <h2>Työtilaukset & asennukset</h2>
        <p>___________________________</p>
        <p><MdAccountCircle /> Jere Nikupeteri</p>
        <p><FaPhoneAlt />  +358 40 595 9064</p>
        <p><MdOutlineEmail/> info@jekotek.fi</p>
        <a href="mailto:info@jekotek.fi">Ota yhteyttä sähköpostilla</a>
      </div>
    </div>
  );
}