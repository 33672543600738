import React, { useState } from 'react';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

import './Home.css';

export default function Home() {
  const [teslaImage, setTeslaImage] = useState(0);
  const [defaImage, setDefaImage] = useState(0);
  const [goe, setGoeImage] = useState(0);
  const [zaptec, setZaptecImage] = useState(0);

  const nextImage = () => {
    setTeslaImage((prevIndex) => (prevIndex + 1) % 4);
  };

  // Funktio, joka vaihtaa kuvaa taaksepäin
  const prevImage = () => {
    setTeslaImage((prevIndex) => (prevIndex - 1 + 4) % 4);
  };

  const nextImage2 = () => {
    setDefaImage((prevIndex) => (prevIndex + 1) % 3);
  };

  const prevImage2 = () => {
    setDefaImage((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  const nextImage3 = () => {
    setGoeImage((prevIndex) => (prevIndex + 1) % 3);
  };

  const prevImage3 = () => {
    setGoeImage((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  const nextImage4 = () => {
    setZaptecImage((prevIndex) => (prevIndex + 1) % 3);
  };
  const prevImage4 = () => {
    setZaptecImage((prevIndex) => (prevIndex - 1 + 3) % 3);
  };




  const teslaimages = [
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-1.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-2.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-3.jpg',
    'https://storage.googleapis.com/eletech/tesla-wall-connector-latausasema-4.jpg'
  ];

  const defaimages = [
    'https://storage.googleapis.com/eletech/defa-power-latausasema-ja-rfid-avaintagi.png',
    'https://storage.googleapis.com/eletech/DEFA%20Power%20sein%C3%A4ll%C3%A4.jpg',
    'https://storage.googleapis.com/eletech/Power_charging_lady_height_2948_1000x1000%20(2).jpeg'

  ];

  const goeimages = [
    'https://storage.googleapis.com/eletech/gemini-flex-22kw-smartphone-combo-en%20(1).png',
    'https://storage.googleapis.com/eletech/go-e-charger-gemini-flex-22kw-view4.png',
    'https://storage.googleapis.com/eletech/go-e-charger-gemini-flex-22kw-view43.png'

  ];

  const zaptecImages = [
    'https://storage.googleapis.com/eletech/Zaptec_pro_mid_front.png',
    'https://storage.googleapis.com/eletech/Zaptec%20Pro%20MID.jpg',
    'https://storage.googleapis.com/eletech/Zaptec_september-44.jpg',

  ];


  return (
    <div className='homePage'>
      <p className='homeP'>
        Jekotek tarjoaa ammattitaitoista ja luotettavaa sähköurakointipalvelua Pohjois-Pohjanmaalla ja sen lähialueilla. Pyrimme tarjoamaan asiakkaillemme uusinta ja laadukasta tekniikkaa.
      </p>
      <br />
      <p className="homeP2">
        <br />
        Sähköauton latausratkaisut edullisesti käyttövalmiiksi asennettuna. Hinnat alkaen 160e (sis.alv 25.5%)<br />
        <br />
        <table>
          <tbody>
            <tr>
              <td>Jokainen alla oleva tarjouksemme sisältää teille parhaan latausvaihtoehdon kartoituksen sekä perusasennuspaketin.</td>
            </tr>
            <tr>
              <td>Jos olet jo hankkinut latausaseman, tarjoamme siihen pelkän perusasennuspaketin hintaan 390e.</td>
            </tr>
            <tr>
              Mikäli latausinfrastruktuuri on jo rakennettu, tarjoamme asennuksen edullisemmin.
            </tr>
          </tbody>
        </table>
      </p>
      <br />
      <div class="containerPerusjalisa">
  <div class="perusjalisa">
  <strong>Perusasennus paketti:</strong>
        <br />
        <br />
        <table>
          <tbody>
            <tr>
              <td>-Asennus 11kW-latausteholle</td>
            </tr>
            <tr>
              <td>-15m syöttökaapeli sähkökeskukselta pinta-asennuksena</td>
            </tr>
            <tr>
              <td>-Kytkennät sähkökeskukseen</td>
            </tr>
            <tr>
              <td>-Mahdollinen voimavirtapistorasia</td>
            </tr>
            <tr>
              <td>-Mahdollinen sulake sähkökeskukseen</td>
            </tr>
            <tr>
              <td>-Tarvittavat kiinnitystarvikkeet</td>
            </tr>
            <tr>
              <td>-Tarvittavat läpiviennit (puu, kipsilevy, tiili)</td>
            </tr>
            <tr>
              <td>-Ajokilometrit asennuskohteeseen (max 40km)</td>
            </tr>
            <tr>
              <td>-Käyttöönotto, testaus ja käytönopastus</td>
            </tr>
          </tbody>
        </table>
        <br />
  </div>
  <div class="perusjalisa">
    <strong>Lisäpalveluna asennuksiimme:</strong><br />
        <br />
        <table>
          <tbody>
            <tr>
              <td>-Asennus 22kW-latausteholle</td>
            </tr>
            <tr>
              <td>-Vikavirtasuojan lisäys sähkökeskukseen</td>
            </tr>
            <tr>
              <td>-Sähkökeskuksen laajennustyö</td>
            </tr>
            <tr>
              <td>-Lisämetrit kaapelointiin (yli 15m menevä osuus)</td>
            </tr>
            <tr>
              <td>-Haastavammat läpiviennit</td>
            </tr>
            <tr>
              <td>-Maankaivuutyöt kaapeloinnille</td>
            </tr>
            <tr>
              <td>-Kuormanhallinta järjestelmä</td>
            </tr>
            <tr>
              <td>-Energiamittari</td>
            </tr>
            <tr>
              <td>-Latauslaitteen tolppa</td>
            </tr>
            <tr>
              <td>-Ajokilometrit yli 40km</td>
            </tr>
          </tbody>
        </table>
  </div>
</div>

      <div className="container">
        <div className='defaContainer'>
          <p className="defa">Defa Power</p>
          <img src={defaimages[defaImage]} alt={`Kuva ${defaImage + 1}`} />
          <div className="teslabuttonContainer">
          <GrFormPrevious className='buttonSize' onClick={prevImage2}>
            </GrFormPrevious>
            <GrFormNext className='buttonSize' onClick={nextImage2}>
            </GrFormNext>
          </div>
          <p>
            - 6M Type2 latauskaapeli <br />
            - Pörssisähkölataus<br />
            - RFID-käytönvalvonta<br />
            - Suunniteltu pohjoisessa vallitseviin olosuhteisiin<br />
            - Markkinoiden suosituin<br />
            - 5v Takuu<br />
            <br />
            <a href="https://storage.googleapis.com/eletech/defa_tekst.pdf" target="_blank" rel="noopener noreferrer" color="black">DEFA LISÄTIEDOT</a>
            <br />
            <br />
            Hinta asennettuna 1174 e<br />
          </p>
        </div>

        <div className='teslaContainer'>
          <p className="tesla">Tesla Wall Connector</p>
          <img src={teslaimages[teslaImage]} alt={`Kuva ${teslaImage + 1}`} />
          <div className="teslabuttonContainer">
            <GrFormPrevious className='buttonSize' onClick={prevImage}>
            </GrFormPrevious>
            <GrFormNext className='buttonSize' onClick={nextImage}>
            </GrFormNext>
          </div>
          <p>
            - 7,5m kiinteä Type2 latauskaapeli<br />
            - Tyylikäs muotoilu<br />
            - Helppokäyttöinen Tesla sovelluksella<br />
            - Päivitykset langattoman verkon kautta pitävät Wall Connectorin aina ajan tasalla<br />
            - 3v takuu<br />
            <br />
            <a href="https://storage.googleapis.com/eletech/Gen_3_Tesla_Wall_Connector_manuaali_suomeksi.pdf" target="_blank" rel="noopener noreferrer">WALL CONNECTOR LISÄTIEDOT</a>
            <br />
            <br />
            Hinta asennettuna 940 e
          </p>
        </div>
        <div className='geminiContainer'>
          <p className="gemini">go-e Charger Gemini Flex 11kW</p>
          <img src={goeimages[goe]} alt={`Kuva ${goe + 1}`} />
          <div className="teslabuttonContainer">
          <GrFormPrevious className='buttonSize' onClick={prevImage3}>
            </GrFormPrevious>
            <GrFormNext className='buttonSize' onClick={nextImage3}>
            </GrFormNext>
          </div>
          <p>
            - Mahdollisuus ottaa mukaan esimerkiksi mökille <br />
            - Seinäkiinnike<br />
            - RFID -käytönvalvonta  <br />
            - 7v Takuu <br />
            <br />
            <a href="https://cdn.shopify.com/s/files/1/0465/4412/6112/files/go_e_gemini_flex_tuotekortti.pdf?v=1694090145" target="_blank" rel="noopener noreferrer">GO-E GEMINI FLEX LISÄTIEDOT</a>
            <br />
            <br />
            Hinta asennettuna 1116 e
          </p>
        </div>

        <div className='zaptecContainer'>
          <p className="gemini">Zaptec Pro</p>
          <img src={zaptecImages[zaptec]} alt={`Kuva ${zaptec + 1}`} />
          <div className="teslabuttonContainer">
          <GrFormPrevious className='buttonSize' onClick={prevImage4}>
            </GrFormPrevious>
            <GrFormNext className='buttonSize' onClick={nextImage4}>
            </GrFormNext>
          </div>
          <p>
            - Suunniteltu taloyhtiöille ja yrityksille <br />
            - Täysin ilmainen 4G-yhteys, etäohjaus ja päivitykset <br />
            - Saatavilla kuormanhallinta, jossa käytössä ainutlaatuinen vaihetasapainotus <br />
            - Saatavilla näytöllisellä MID- energiamittarilla <br />
            - 5v takuu <br />
            <br />
            <a href="https://storage.googleapis.com/eletech/Zaptec%20Pro.pdf" target="_blank" rel="noopener noreferrer">ZAPTEC LISÄTIEDOT</a>
            <br />
            <br />
            Hinta asennettuna 1725 e<br />
          </p>
        </div>

        <div className='garoContainer'>
          <p className="Garo">Garo GLBDC-T222FC</p>
          <img src='https://storage.googleapis.com/eletech/GARO.jpg' alt='Kuva' />
          <p>
            - 4,5m Type2 kiinteä latauskaapeli <br />
            - 2v takuu<br />
            <br />
            <a href="https://www.sahkonumerot.fi/3501514/doc/brochure/" target="_blank" rel="noopener noreferrer">GARO LISÄTIEDOT</a>
            <br />
            <br />
            Hinta vaihtelee tukkuhintojen mukaan, lisätietoa saat kysymällä<br />
          </p>
        </div>
        <div className='pwrContainer'>
          <p className="PWRBOX">PWR BOX</p>
          <img src='https://storage.googleapis.com/eletech/PWR%20BOX.jpg' alt='Kuva' />
          <p>
          - Mahdollista asentaa seinään tai tolppaan   <br />
          - Pistorasiat napilla vaihdettavissa latauksesta lämmitykseen ja takaisin    <br />
          <br />
            <a href="https://sahkonumerot.fi/3593200/doc/brochure/" target="_blank" rel="noopener noreferrer">PWR BOX LISÄTIEDOT</a>
            <br />
            <br />
            Hinta vaihtelee tukkuhintojen mukaan, lisätietoa saat kysymällä
          </p>
        </div>
      </div>
      <br />
      <br />
      <p className="homeP3">
        Ota yhteyttä ja varaa ilmainen kartoitus yhteystietolomakkeemme kautta<a href="/about"> täältä.</a>
      </p>
      <br/>
      <p className="homeP4">
        <a href="/references"> Katso referenssimme täältä</a>
      </p>
      <div className='urakka'>
      <a
        target="_blank"
        rel="noopener noreferrer" 
        href="https://www.urakkamaailma.fi/urakoitsija/jekotek-oy?utm_source=contractor-badge&utm_medium=add-on&utm_campaign=contractor-badge" 
        style={{ textDecoration: 'none' }}>
        <img alt="Urakkamaailma.fi" 
        src="https://www.urakkamaailma.fi/arvostelu-kuva/jekotek-oy" 
        style={{ borderRadius: 10 }}/>
        <br/>
        <span 
        style={{
          fontWeight: 'bold',
          fontSize: '12px', 
          marginLeft: '10px', 
          color: 'white' }}>
          Remontti ja rakentaminen - Urakkamaailma.fi</span>
        </a>
        <div className='zettle'>
          <img  className='zettleSize' src="https://storage.googleapis.com/eletech/zettle.jpeg" alt="Urakkamaailma.fi" />
          <p>Meillä voit myös pilkkoa maksun Klarnalla</p>
          </div>
      </div>
    </div>
  );
}