import React from 'react';
import './Services.css';
export default function Services() {
  return (
    <div className='services'>
      <p className='servicesP'>
      <h2>Kotilatausratkaisut</h2>
      <br />
        Suosittelemme hankkimaan kiinteän latausaseman kotiisi, jos mahdollista. Kiinteä latausasema tarjoaa useita etuja, kuten optimaalisen lataustehon ja akkujen elinkaaren pidentämisen, mikä tekee siitä autovalmistajien ja asiantuntijoiden suositteleman vaihtoehdon.
        Valitessasi oikean latausaseman kotiisi on tärkeää ottaa huomioon asumismuotosi ja sähköliitäntäsi ominaisuudet. Rivi- tai kerrostaloasunnoissa latausaseman vanhan lämpötolpan johtoon asentaminen voi olla ainoa vaihtoehto, mikä rajaa lataustehon maksimissaan 3.7 kW (1-vaiheinen asennus).
        Omakotitaloasujilla on yleensä enemmän joustavuutta lataustehon suhteen, ja he voivat hyödyntää kolmivaihevirtaa. Asennamme omakotitaloasujille mielellämme kiinteän latausaseman tai 3-vaihde voimavirtapistorasian latauslaitetta varten.<br />
        <br />
        <h3>Kuormanhallinta </h3>
        <br />
        Dynaamisen kuormanhallinnan toteuttamiseksi sähkökeskukseen asennetaan erillinen kuormanhallintalaite, joka seuraa asuntosi sähkönkulutusta. 
      Tämä laite kommunikoi latausaseman kanssa ja säätää lataustehon dynaamisesti sen mukaan, kuinka paljon sähköä on käytettävissä kiinteistössä.
      Suosittelemme dynaamista kuormanhallintaa, mikäli haluat saada maksimaalisen lataustehon käyttöösi ja sinun ei tarvitse miettiä pääsulakkeiden
      palamista muiden kiinteistön sähkölaitteiden käytön aikana.
      <br />
      <br />
      Manuaalinen kuormanhallinta tarjoaa edullisemman vaihtoehdon dynaamiselle kuormanhallinnalle. 
      Tässä vaihtoehdossa latausteho parametroidaan latausasemalta karkeasti noin puoleen sähkön korkean kulutusajan aikana. 
      Esimerkiksi yöaikaan lataus voidaan asettaa suurempaan tehoon, jolloin hyödynnetään suurempaa latauskapasiteettia.
      Manuaalinen kuormanhallinta kuuluu asennushintaan ja toteutamme sen aina asennuksissamme, mikäli dynaamista 
      kuormanhallintaa ei ole tullut valituksi.
      <br />
      <br />
      <h3>Super suko pistorasia</h3>
      <br />
      Meiltä voit tilata myös edullisemman latausratkaisun super suko pistorasian asennettuna kotiisi. Tässä latausratkaisussa latausteho on tarjoamistamme ratkaisuista pienin,
      joten latausaika ajoneuvollesi on pitkä.
      Asennamme super suko pistorasioita esimerkiksi auton lämmityskoteloiden tilalle.
      <br />
      <br />
      <h3>Taloyhtiöille</h3>
      <br />
      Toteutamme latausratkaisuja sekä kartoituksia myös taloyhtiöille. Taloyhtiöiden latausratkaisut ovat yleensä hieman monimutkaisempia kuin omakotitalojen, sillä
      asukkaiden latausinfra mitoitetaan kiinteistön sähköliittymän mukaan.
        </p>
       
    </div>
  )
}
